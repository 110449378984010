import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { LoginModel } from '@app/domain/LoginModel';
import {
  company,
  deleteToken,
  deleteUser,
  getLoggedInCompanyId,
  persistToken,
  persistUser,
  readToken,
  readUser,
  refreshToken,
} from '@app/services/localStorage.service';
import axios from 'axios';
import { UserModels } from '@app/domain/UserModels';
import { ChangePasswordModel } from '@app/domain/ChangePasswordModel';

export interface LoginState {
  loading: boolean;
  login: UserModels | null;
  passwords: UserModels | null;
  token: string | null;
}

const initialState: LoginState = {
  loading: false,
  login: readUser(),
  passwords: null,
  token: readToken(),
};

export const mlogin = createAsyncThunk('auth/login', async (values, thunkApi) => {
  try {
    const response = await axios.post<UserModels>(`${process.env.REACT_APP_BASE_URL}/Ecommerce/Auth/login`, 
    values);
    persistToken(response.data.accessToken);
    refreshToken(response.data.refreshToken);
    persistUser(response.data);
    company(response.data?.companyId)
    // persistUser(response.data);

    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});
export const mlogout = createAsyncThunk('auth/logout', async (_, thunkApi) => {
  try {
    console.log(readToken());
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/onboarding-app-backend/api/logout`,
      {},
      {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const changePassword = createAsyncThunk('auth/change-password', async (values, thunkApi) => {
  try {
    const response = await axios.put<UserModels[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Users/ChangePassword/companyId/${getLoggedInCompanyId()}/email/${readUser()?.email}/${values?.password}/${values?.confirmPassword}`,
      {
        headers: { "X-Auth-Token": "Bearer " + readToken() },
      }
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const authenticationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // createLogin
    builder.addCase(mlogin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(mlogin.fulfilled, (state, action: PayloadAction<UserModels>) => {
      state.loading = false;
      state.login = action.payload;
    });
    builder.addCase(mlogin.rejected, (state, action) => {
      state.loading = false;
      // state.login = [];
    });
    // createLogout
    builder.addCase(mlogout.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(mlogout.fulfilled, (state, action) => {
      state.loading = false;
      // state.login = action.payload.data;
    });
    builder.addCase(mlogout.rejected, (state, action) => {
      state.loading = false;
      // state.login = [];
    });
    // changePassword
    builder.addCase(changePassword.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action: PayloadAction<UserModels>) => {
      state.loading = false;
      state.login = action.payload.data;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loading = false;
      state.login = null;
    });
  },
});

export const thunks = {
  mlogin,
  mlogout,
  changePassword,
};

export default authenticationSlice.reducer;
