import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ImageModel } from '@app/domain/ImageModel';
import axios from 'axios';

export interface ImageState {
  loading: boolean;
  fileName: string | null;
  message: string | null;
}

const initialState: ImageState = {
  loading: false,
  fileName: null,
  message: null,
};

export const imageUpload = createAsyncThunk('products/imageUpload', async (formData, thunkApi) => {
  try {
    const response = await axios.post<ImageModel[]>(`${process.env.REACT_APP_BASE_URL}/Ecommerce/Image/Upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const imageSlice = createSlice({
  name: 'image',
  initialState, // set the initial state to an empty object
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(imageUpload.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(imageUpload.fulfilled, (state, action: PayloadAction<ImageModel[]>) => {
      state.loading = false;
      state.message = action.payload.message;
    });
    builder.addCase(imageUpload.rejected, (state, action) => {
      state.loading = false;
      state.message = null;
    });
  },
});

export const thunks = {
  imageUpload,
};

export default imageSlice.reducer;
