import { Card } from 'antd';
import { ReactChild, ReactFragment, ReactPortal } from 'react';

function OrderCard(props: {
  name: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
  value: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
  color: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
  imageUrl: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined;
}) {
  const newStyle = { fontSize: '24px', color: props?.color, transition: 'color 0.3s' };

  return (
    <>
      <Card bodyStyle={{ height: '120px', padding: '25px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <img src={props.imageUrl} alt="My Image" style={{ height: 60, width: 60, marginLeft: '-35px' }} />
          <div style={{ width: '65%', height: 60, fontWeight: 'bold' }}>
            <h1>{props?.name}</h1>
            <div style={newStyle}>{props.value}</div>
          </div>
        </div>
      </Card>
    </>
  );
}

export default OrderCard;
