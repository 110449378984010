import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ProductModel,ProductDetailModel, Product, BaseResponse } from '@app/domain/ProductModel';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { ThemeSetupModel } from '@app/domain/ThemeSetupModel';

export interface ThemeSetupState {
  loading: boolean;
  ThemeSetups: ThemeSetupModel[] | [];
  ThemeSetup: ThemeSetupModel | null;

}

const initialState: ThemeSetupState = {
  loading: false,
  ThemeSetups: [],
  ThemeSetup: null,
};

export const addThemeSetup = createAsyncThunk(
  "/theme/setup-add",
  async (values, thunkApi) => {
    try {
      const response = await axios.post<ThemeSetupModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/ThemeSetup/SaveThemeSetup`,
        {...values, companyId: getLoggedInCompanyId()},
        {
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const ThemeSetupList = createAsyncThunk(
  "/theme/setup-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get<ThemeSetupModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/ThemeSetup/GetAllThemeSetupList`,
        {
          params: {
            companyId: getLoggedInCompanyId(),
          },
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const UpdateThemeSetup = createAsyncThunk(
  "theme/setup-update",
  async (values, thunkApi) => {
    console.log(values);
    try {
      const response = await axios.put<ThemeSetupModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/ThemeSetup/UpdateThemeSetup`,
         {...values, companyId: getLoggedInCompanyId()},
        {
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const GoLiveThemeSetup = createAsyncThunk(
  "theme/setup-go-live",
  async (values, thunkApi) => {
    console.log(values);
    try {
      const response = await axios.put<ThemeSetupModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/ThemeSetup/GoLiveThemeSetup?id=${values?.id}&companyId=${getLoggedInCompanyId()}`,
         {
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const ThemeSetupDetails = createAsyncThunk(
  "theme/setup-view",
  async (id, thunkApi) => {
    try {
      const response = await axios.get<ThemeSetupModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/ThemeSetup/GetThemeSetupDetailsById`,{
          params: 
          {
            Id: id
          }
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const deleteThemeSetup = createAsyncThunk('theme/setup-delete', async (id, thunkApi) => {
  try {
    const response = await axios.delete<ThemeSetupModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/ThemeSetup/DeleteThemeSetup`,
      {
        params: {
          Id: id
        },
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});
  
const ThemeSetupSlice = createSlice({
  name: 'themeSetup',
  initialState,
  reducers: {},
  extraReducers: builder => {
      // list
      builder.addCase(ThemeSetupList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(ThemeSetupList.fulfilled,(state, action: PayloadAction<ThemeSetupModel[]>) => {
          state.loading = false;
          state.ThemeSetups = action.payload;
      });
      builder.addCase(ThemeSetupList.rejected, (state, action) => {
        state.loading = false;
        state.ThemeSetups = [];
      });
      builder.addCase(ThemeSetupDetails.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(ThemeSetupDetails.fulfilled,(state, action: PayloadAction<ThemeSetupModel>) => {
          state.loading = false;
          state.ThemeSetup = action.payload;
      });
      builder.addCase(ThemeSetupDetails.rejected, (state, action) => {
        state.loading = false;
        state.ThemeSetup = null;
      });
      builder.addCase(GoLiveThemeSetup.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(GoLiveThemeSetup.fulfilled,(state, action: PayloadAction<ThemeSetupModel>) => {
          state.loading = false;
          state.ThemeSetup = action.payload;
      });
      builder.addCase(GoLiveThemeSetup.rejected, (state, action) => {
        state.loading = false;
        state.ThemeSetup = null;
      });
  }
})

export const thunks = {
  ThemeSetupList,
  ThemeSetupDetails,
  UpdateThemeSetup,
  addThemeSetup
};

export default ThemeSetupSlice.reducer;
