import React from 'react';
import { Button as BaseButton } from 'antd';
import styled from 'styled-components';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BASE_COLORS } from '@app/styles/themes/constants';
import logo from 'assets/logo.png';
import logoDark from 'assets/logo-dark.png';

export const GitHubButton: React.FC = (props) => {
  const theme = useAppSelector((state) => state.theme.theme);

  const icon = (
    <img
      src={theme === 'dark' ? logoDark : logo}
      alt="Logo"
      style={{ width: '40px', height: '40px', alignItems: 'center', display: 'revert', marginRight: '15px' }}
    />
  );

  return (
   null
  );
};

const Button = styled(BaseButton)<{ $isDark: boolean }>`
  // color: ${(props) => BASE_COLORS[props.$isDark ? 'white' : 'black']};
  // background: ${(props) => BASE_COLORS[props.$isDark ? 'black' : 'white']};
  // border-radius: 50px;
  // padding-top: 0;
  // padding-bottom: 0;
  // display: flex;
  // align-items: center;

  // &:hover,
  // &:active,
  // &:focus {
  //   color: ${(props) => BASE_COLORS[props.$isDark ? 'black' : 'white']};
  //   background: ${(props) => BASE_COLORS[props.$isDark ? 'white' : 'black']};
  }
`;
