export enum ModuleName {
  CATEGORY = 'Category',
  SUB_CATEGORY = 'SubCategory',
  PRODUCT = 'Product',
  PRODUCT_VARIANT = 'Product Variant',
  BRAND = 'Brand',
  PROMOTION = 'Promotion',
  PROMOTION_BANNER = 'Promotion Banner',
  BLOG = 'Blog',
  COMPANY = 'Companies',
  OGIMAGE = 'Ogimage',
  FAVICON='Favicon'
}
