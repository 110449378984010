import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { ConfigurationModel } from '@app/domain/ConfigurationModel';

export interface ConfigurationState {
  loading: boolean;
  themes: ConfigurationModel[] | [];
  APIkey: string | null;
}

const initialState: ConfigurationState = {
  loading: false,
  themes: [],
  APIkey: null,
};

export const themeList = createAsyncThunk('/theme/theme-list', async (_, thunkApi) => {
  try {
    const response = await axios.get<ConfigurationModel[]>(`${process.env.REACT_APP_BASE_URL}/Ecommerce/Get/AppTheme`, {
      params: {
        companyId: getLoggedInCompanyId(),
      },
      headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const manageTheme = createAsyncThunk('theme/manage-theme', async (id, thunkApi) => {
  try {
    const response = await axios.put<ConfigurationModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Change/AppTheme/id/${id}`,
      {
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const apiKeyGenerate = createAsyncThunk('theme/generate-api-key', async (_, thunkApi) => {
  try {
    const response = await axios.post<ConfigurationModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/GenerateAPIKey`,
      {
        params: {
          companyId: getLoggedInCompanyId(),
        },
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const getAPIKey = createAsyncThunk('theme/api-key', async (_, thunkApi) => {
  try {
    const response = await axios.get<ConfigurationModel>(`${process.env.REACT_APP_BASE_URL}/Ecommerce/Get/ApiKey`, {
      params: {
        companyId: getLoggedInCompanyId(),
      },
      headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
    });
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const ConfigurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(themeList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(themeList.fulfilled, (state, action: PayloadAction<ConfigurationModel[]>) => {
      state.loading = false;
      state.themes = action.payload;
    });
    builder.addCase(themeList.rejected, (state, action) => {
      state.loading = false;
      state.themes = [];
    });
    builder.addCase(getAPIKey.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAPIKey.fulfilled, (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.APIkey = action.payload;
    });
    builder.addCase(getAPIKey.rejected, (state, action) => {
      state.loading = false;
      state.APIkey = null;
    });
  },
});

export const thunks = {
  themeList,
  getAPIKey
};

export default ConfigurationSlice.reducer;
