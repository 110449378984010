import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {  Col, Row } from 'antd';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { CompanyDetails } from '@app/store/slices/companySlice';
import { EyeOutlined } from '@ant-design/icons';
import { Button } from 'components/common/buttons/Button/Button';
import CryptoJS from 'crypto-js';
import { secretKey } from '@app/utils/utils';
import { Buffer } from 'buffer';


interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const { company } = useAppSelector((state) => state.company);
  const dispatch = useDispatch();
  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <Row justify="space-between">
        <Col xl={15} xxl={12}>
          {/* <HeaderSearch /> */}
        </Col>
        <Col>
          <S.GHButton />
        </Col>
      </Row>
    </S.SearchColumn>
  ) : (
    <>
      <Col lg={10} xxl={8}>
        {/* <HeaderSearch /> */}
      </Col>
      <Col>
        <S.GHButton />
      </Col>
    </>
  );

  useEffect(() => {
    dispatch(CompanyDetails(localStorage.getItem('companyId')));
  }, []);

  const handleClick = () => {
  const website = company?.length && company[0]?.website;
  if(website && website?.includes('preview')){
    const encrypted = CryptoJS.AES.encrypt(website.substring(website.indexOf("/") + 1, website.indexOf("/preview")), secretKey).toString();
    const base64 = Buffer.from(encrypted).toString('base64');
    const base64UrlSafe = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
   
    // const data = CryptoJS.AES.encrypt(
    //   JSON.stringify(website.substring(website.indexOf("/") + 1, website.indexOf("/preview"))),
    //   secretKey
    // ).toString();
    window.open(`https://${website.substring(0, website.indexOf("/") + 1)}${base64UrlSafe}/preview`, '_blank', 'noreferrer');
  } else if(website) {
    window.open(`https://${website}`, '_blank', 'noreferrer');
  }
  };

  return (
    <Row justify="space-between" align="middle">
      {leftSide}

      <S.ProfileColumn xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <Row align="middle" justify="end" gutter={[10, 10]}>
          <Col>
            <Row gutter={[{ xxl: 10 }, { xxl: 10 }]}>
              <div style={{marginTop: '15px'}}>
              <Button htmlType="submit" type="primary" style={{ width:'115px', height:'40px'}} onClick={handleClick}>
                <EyeOutlined />
                Preview
              </Button>
              </div>
              {/* <Col>
                <HeaderFullscreen />
              </Col>

              <Col>
                <NotificationsDropdown />
              </Col> */}

              {/* <Col>
                <SettingsDropdown />
              </Col> */}
            </Row>
          </Col>

          <Col>{/* <ProfileDropdown /> */}</Col>
        </Row>
      </S.ProfileColumn>
    </Row>
  );
};
