import { Col, Row } from 'antd';
import { NotificationType } from '@app/components/common/Notification/Notification';
import { CurrencyType } from '@app/interfaces/interfaces';
import { Priority } from '@app//constants/enums/priorities';
import { ReactComponent as ETHIcon } from '@app/assets/icons/eth.svg';
import { ReactComponent as BTCIcon } from '@app/assets/icons/btc.svg';
import { ImageEndPointPrefix } from '@app/constants/enums/imageEndPointPrefix';
import { ModuleName } from '@app/constants/enums/moduleName';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';

export const secretKey = '7wt@mak1n0s';

export const camelize = (string: string): string => {
  return string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');
};

export const getCurrencyPrice = (
  price: number | string,
  currency: CurrencyType,
  isIcon = true,
): string | React.ReactNode => {
  switch (currency) {
    case 'USD': {
      return isIcon ? `$${price}` : `${price} USD`;
    }

    case 'BTC': {
      return isIcon ? (
        <Row align="middle" gutter={[8, 8]}>
          <Col style={{ display: 'flex' }}>
            <BTCIcon />
          </Col>

          <Col>{price}</Col>
        </Row>
      ) : (
        `${price} BTC`
      );
    }

    case 'ETH': {
      return isIcon ? (
        <Row align="middle" gutter={[8, 8]}>
          <Col style={{ display: 'flex' }}>
            <ETHIcon />
          </Col>

          <Col>{price}</Col>
        </Row>
      ) : (
        `${price} ETH`
      );
    }

    default: {
      return isIcon ? `$${price}` : `${price} USD`;
    }
  }
};

type MarkArea = {
  xAxis: number;
};

export const getMarkAreaData = (data: string[] | number[]): MarkArea[][] =>
  data.map((el, index) => [
    {
      xAxis: 2 * index,
    },
    {
      xAxis: 2 * index + 1,
    },
  ]);

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const hexToRGB = (hex: string): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};

export const getDifference = (value: number, prevValue: number): string | null =>
  prevValue !== 0 ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%` : '100%';

export const normalizeProp = (prop: string | number | [number, number]): string =>
  typeof prop === 'number' ? `${prop}px` : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)';
    case Priority.LOW:
      return 'var(--success-color)';
    case Priority.MEDIUM:
      return 'var(--warning-color)';
    case Priority.HIGH:
      return 'var(--error-color)';
    case Priority.CRITICAL:
      return 'var(--secondary-color)';
    case Priority.URGENT:
      return 'var(--dark-color)';
    case Priority.BROWN:
      return 'var(--dark-brown-color)';
    default:
      return 'var(--success-color)';
  }
};

export const defineColorBySeverity = (severity: NotificationType | undefined, rgb = false): string => {
  const postfix = rgb ? 'rgb-color' : 'color';
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`;
    case 'info':
    default:
      return `var(--primary-${postfix})`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeBy = (a: any[], b: any[], key: string): any[] =>
  a.filter((elem) => !b.find((subElem) => subElem[key] === elem[key])).concat(b);

export const getSmoothRandom = (factor: number, start: number): number => {
  const halfEnvelope = 1 / factor / 2;
  const max = Math.min(1, start + halfEnvelope);
  const min = Math.max(0, start - halfEnvelope);

  return Math.random() * (max - min) + min;
};

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return {
      h,
      s,
      l,
    };
  } else {
    throw new Error('Non valid HEX color');
  }
};

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getImagePathByModule = (moduleName: string, id: number, childId = 0): string => {
  console.log('test ', process.env.REACT_APP_BASE_URL);
  if (id <= 0) return '';
  let imagePath = process.env.REACT_APP_BASE_URL ?? '';
  switch (moduleName) {
    case ModuleName.CATEGORY:
      imagePath += ImageEndPointPrefix.CATEGORY;
      break;
    case ModuleName.SUB_CATEGORY:
      imagePath += ImageEndPointPrefix.SUB_CATEGORY;
      break;
    case ModuleName.PRODUCT:
      imagePath += ImageEndPointPrefix.PRODUCT + id + ImageEndPointPrefix.IMAGE + childId;
      break;
    case ModuleName.PRODUCT_VARIANT:
      imagePath += ImageEndPointPrefix.PRODUCT + id + ImageEndPointPrefix.VARIANT + childId;
      break;
    case ModuleName.BRAND:
      imagePath += ImageEndPointPrefix.BRAND;
      break;
    case ModuleName.PROMOTION:
      imagePath += ImageEndPointPrefix.PROMOTION;
      break;
    case ModuleName.PROMOTION_BANNER:
      imagePath += ImageEndPointPrefix.PROMOTION_BANNER;
      break;
    case ModuleName.BLOG:
    imagePath += ImageEndPointPrefix.BLOG;
    break;
    case ModuleName.COMPANY:
      imagePath += ImageEndPointPrefix.COMPANY;
      break;
      case ModuleName.OGIMAGE:
      imagePath += ImageEndPointPrefix.OGIMAGE;
      break;
      case ModuleName.FAVICON:
        imagePath += ImageEndPointPrefix.FAVICON;
        break;
    default:
      imagePath = '';
      break;
  }
  return childId ? imagePath : imagePath + id ?? '';
};

export const fulfillmentStatus = {
  'PENDING': {
    key: 'Pending',
    value: 'V'
  },
  'CONFIRMED': {
    key: 'Confirmed',
    value: 'CF'
  },
  'REJECTED': {
    key: 'Rejected',
    value: 'RJ'
  },
  'READYTOPICKUP': {
    key: 'Ready To Pickup',
    value: 'RP'
  },
  'INTRANSIT': {
    key: 'Intransit',
    value: 'I'
  },
  'DELIVERED': {
    key: 'Delivered',
    value: 'D'
  },
};

export const reversalStatus = {
  'PENDINGREVERSALSTATUS': {
    key: 'Pending',
    value: 'NA'
  },
  //Return
  'RETURNREQUEST': {
    key: 'Change Product Request',
    value: 'RRQ'
  },
  'RETURNAPPROVED': {
    key: 'Change Product Approve',
    value: 'RA'
  },
  'RETURNREJECTED': {
    key: 'Change Product Reject',
    value: 'RD'
  },
  'RETURNINTRANSIT': {
    key: 'Intransit',
    value: 'RI'
  },
  'RETURNRECEIVED': {
    key: 'Return Received',
    value: 'RRE'
  },
  'RETURNPENDING': {
    key: 'Return Pending',
    value: 'RP'
  },
  'PENDINGTORETURN': {
    key: 'Pending To Return',
    value: 'RV'
  },
  'READYTORETURN': {
    key: 'Ready To Return',
    value: 'RR'
  },
  'RETURNED': {
    key: 'Returned',
    value: 'R'
  },
  //Refund
  'REFUNDREQUESTED': {
    key: 'Refund Request',
    value: 'RFR'
  },
  'REFUNDAPPROVED': {
    key: 'Refund Approved',
    value: 'RFA'
  },
  'REFUNDREJECTED': {
    key: 'Refund Rejected',
    value: 'RFD'
  },
  'REFUNDED': {
    key: 'Refunded',
    value: 'RF'
  },
  'PRODUCTRETURNREFUNDREQUEST': {
    key: 'Cancel Product Request',
    value: 'RFPR'
  },
  'PRODUCTRETURNREFUNDAPPROVE': {
    key: 'Cancel Product Request Approve',
    value: 'RFPA'
  },
  'PRODUCTRETURNREFUNDREJECT': {
    key: 'Cancel Product Request Reject',
    value: 'RFPRD'
  },
  'PRODUCTWORETURNREFUNDREQUEST': {
    key: 'Without Product Return Refund Request',
    value: 'RFR'
  },
  'PRODUCTWORETURNREFUNDAPPROVE': {
    key: 'Without Product Return Refund Approve',
    value: 'RFA'
  },
  'PRODUCTWORETURNREFUNDREJECT': {
    key: 'Without Product Return Refund Reject',
    value: 'RFRD'
  },

  
  'REFUNDPRODUCTPENDINGRETURN': {
    key: 'Cancel Product Pending Return',
    value: 'RFRV'
  },
  'REFUNDPRODUCTREADYTORETURN': {
    key: 'Cancel Product Ready To Return',
    value: 'RFRR'
  },
  'REFUNDPRODUCTRETURNINTRANSIT': {
    key: 'Cancel Product Return Intransit',
    value: 'RFRI'
  },
  'REFUNDPRODUCTRETURNRECEIVED': {
    key: 'Cancel Product Return Received',
    value: 'RFRRE'
  },
}

export const mapStatusToRenderedStatus = (status: string) => {
  switch (status) {
    case 'RJ':
      return <Status color={defineColorByPriority(Priority.HIGH)} text={'Rejected'} />;
    case 'C':
      return <Status color={defineColorByPriority(Priority.HIGH)} text={'Cancelled'} />;
    case 'CF':
      return <Status color={defineColorByPriority(Priority.URGENT)} text={'Confirmed'} />;
    case 'RA':
      return <Status color={defineColorByPriority(Priority.INFO)} text={'ReAssigned'} />;
    case 'AT':
      return <Status color={defineColorByPriority(Priority.LOW)} text={'Tracking Added'} />;
    case 'RP':
      return <Status color={defineColorByPriority(Priority.INFO)} text={'Ready To Pickup'} />;
    case 'D':
      return <Status color={defineColorByPriority(Priority.LOW)} text={'Delivered'} />;
    case 'V':
    case 'RV':
    case 'RFRV':
      return <Status color={defineColorByPriority(Priority.CRITICAL)} text={status === 'V' ? 'Pending' : status == 'RV' ? 'Pending to Return' : 'Cancel Prod Pending Return'} />;
    case 'RR':
    case 'RFRR':
      return <Status color={defineColorByPriority(Priority.BROWN)} text={status == 'RR' ? 'Ready To Return' : 'Cancel Prod Ready Return'} />;
    case 'I':
    case 'RFRI':
      return <Status color={defineColorByPriority(Priority.MEDIUM)} text={status == 'I' ? 'In Transit' : 'Cancel Prod Return Intransit'} />;
    case 'RRE':
    case 'RFRRE':
      return <Status color={defineColorByPriority(Priority.BROWN)} text={status == 'RRE' ? 'Return Received' : 'Cancel Prod Return Received'} />;
    default:
      return status;
  }
}


export const mapReversalToRenderedStatus = (status: string) => {
  switch (status) {
    case 'NA':
      return <Status color={defineColorByPriority(Priority.URGENT)} text={'Not Applicable'} />;
      case 'RAT':
      return <Status color={defineColorByPriority(Priority.INFO)} text={'Return Tracking Added'} />;
      case 'CRAT':
        return <Status color={defineColorByPriority(Priority.INFO)} text={'Cancel Product Return Tracking Added'} />;
    case 'RFR':
    case 'RRQ':
    case 'RFPR':
    return <Status color={defineColorByPriority(Priority.BROWN)} text={status == 'RRQ' ? 'Change Product Requested' : status == 'RFR' ? 'W/O Product Refund Requested' : 'Cancel Product Requested'} />;
    case 'RD':
    case 'RFRD':
    case 'RFPRD':
      return <Status color={defineColorByPriority(Priority.HIGH)} text={status == 'RD' ? 'Change Product Rejected' : status == 'RFPRD' ? 'Cancel Product Rejected' : 'W/O Product Refund Rejected'} />;
    case 'RA':
    case 'RFA':
    case 'RFPA':
      return <Status color={defineColorByPriority(Priority.LOW)} text={status == 'RA' ? 'Change Product Approved' : status == 'RFPA' ? 'Cancel Product Approved' : 'W/O Product Refund Approved'} />;
    default:
      return <Status color={defineColorByPriority(Priority.BROWN)} text={status} />;
  }
}
