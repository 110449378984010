import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ProductModel, ProductDetailModel, Product, BaseResponse } from '@app/domain/ProductModel';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { PromotionModel } from '@app/domain/PromotionModel';
import { ShippingProfileModel } from '@app/domain/ShippingProfileModel';

export interface ShippingProfileState {
  loading: boolean;
  ShippingProfiles: ShippingProfileModel[] | [];
  ShippingDetailProfiles: ShippingProfileModel[] | [];
}

const initialState: ShippingProfileState = {
  loading: false,
  ShippingProfiles: [],
  ShippingDetailProfiles: [],
};

export const ShippingFirstProfileList = createAsyncThunk(
  "/shippingProfileList/shippingProfileList-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get<ShippingProfileModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/ShippingProfile/GetAllFirstShipProfileList`,
        {
          params: {
            companyId: getLoggedInCompanyId(),
          },
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const AddShipProfileList = createAsyncThunk(
  '/shippingProfile/add-shippingProfile-list',
  async (values, thunkApi) => {
    try {
      const response = await axios.post<ShippingProfileModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/ShippingProfile/SaveShippingProfileList`,
        values,
        {
          headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);

export const UpdateShipProfileList = createAsyncThunk(
  '/shippingProfile/update-shippingProfile-list',
  async (values, thunkApi) => {
    try {
      const response = await axios.put<PromotionModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/ShippingProfile/UpdateShippingProfileList`,
        values,
        {
          headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
        },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);

export const ShippingProfileDetails = createAsyncThunk('shippingProfile/view-shippingProfile', async (id, thunkApi) => {
  try {
    const response = await axios.get<PromotionModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/ShippingProfile/GetShippingProfileDetailsById`,
      {
        params: {
          ProfileId: id,
          companyId: getLoggedInCompanyId(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const DeleteShippingProfile = createAsyncThunk('shippingProfile/delete-shippingProfile', async (values, thunkApi) => {
  try {
    const response = await axios.delete<PromotionModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/ShippingProfile/DeleteShippingProfile`,
      {
        params: {
          ProfileId: values?.id,
          CompanyId: getLoggedInCompanyId(),
          ReplaceProfileId: values?.replaceProfileId,
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const ShippingProfileSlice = createSlice({
  name: 'shippingProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(ShippingFirstProfileList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(ShippingFirstProfileList.fulfilled, (state, action: PayloadAction<ShippingProfileModel[]>) => {
      state.loading = false;
      state.ShippingProfiles = action.payload;
    });
    builder.addCase(ShippingFirstProfileList.rejected, (state, action) => {
      state.loading = false;
      state.ShippingProfiles = [];
    });
    builder.addCase(ShippingProfileDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(ShippingProfileDetails.fulfilled, (state, action: PayloadAction<ShippingProfileModel>) => {
      state.loading = false;
      state.ShippingDetailProfiles = action.payload;
    });
    builder.addCase(ShippingProfileDetails.rejected, (state, action) => {
      state.loading = false;
      state.ShippingDetailProfiles = [];
    });
  },
});

export const thunks = {
  ShippingFirstProfileList,
  ShippingProfileDetails,
  UpdateShipProfileList,
  AddShipProfileList,
};

export default ShippingProfileSlice.reducer;
