import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';

export interface CompanyState {
  loading: boolean;
  orders: [] | null;
  sales: [] | null;
  ItemQuantity: [] | null;
  Refund: [] | null;
  FulfillmentForward: [] | null;
  FulfillmentBackward: [] | null;
}

const initialState: CompanyState = {
  loading: false,
  orders: null,
  sales: null,
  ItemQuantity: null,
  Refund: null,
  FulfillmentForward: null,
  FulfillmentBackward: null,
};

export const getOrderCount = createAsyncThunk('/Dashboard/order-count', async (date, thunkApi) => {
  try {
    const response = await axios.get<[]>(`${process.env.REACT_APP_BASE_URL}/Ecommerce/Dashboard/GetOrder`, {
      params: {
        companyId: getLoggedInCompanyId(),
        fromDate: date?.fromDate,
        toDate: date?.toDate,
      },
      headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const getSaleCount = createAsyncThunk('/Dashboard/sale-count', async (type, thunkApi) => {
  try {
    const response = await axios.get<[]>(`${process.env.REACT_APP_BASE_URL}/Ecommerce/Dashboard/GetSale`, {
      params: {
        companyId: getLoggedInCompanyId(),
        Type: type,
      },
      headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const getItemQuantityCount = createAsyncThunk('/Dashboard/item-quantity-count', async (date, thunkApi) => {
  try {
    const response = await axios.get<[]>(`${process.env.REACT_APP_BASE_URL}/Ecommerce/Dashboard/GetItemQuantity`, {
      params: {
        companyId: getLoggedInCompanyId(),
        fromDate: date?.fromDate,
        toDate: date?.toDate,
      },
      headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const getRefundCount = createAsyncThunk('/Dashboard/refund-count', async (date, thunkApi) => {
  try {
    const response = await axios.get<[]>(`${process.env.REACT_APP_BASE_URL}/Ecommerce/Dashboard/GetRefund`, {
      params: {
        companyId: getLoggedInCompanyId(),
        fromDate: date?.fromDate,
        toDate: date?.toDate,
      },
      headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const getFulfillmentForward = createAsyncThunk(
  '/Dashboard/Dashboard-Fulfillment-Forward-count',
  async (date, thunkApi) => {
    try {
      const response = await axios.get<[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Dashboard/GetFulfillmentForward`,
        {
          params: {
            companyId: getLoggedInCompanyId(),
            fromDate: date?.fromDate,
            toDate: date?.toDate,
          },
          headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);

export const getFulfillmentBackward = createAsyncThunk(
  '/Dashboard/Dashboard-Fulfillment-Backward-count',
  async (date, thunkApi) => {
    try {
      const response = await axios.get<[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Dashboard/GetFulfillmentBackward`,
        {
          params: {
            companyId: getLoggedInCompanyId(),
            fromDate: date?.fromDate,
            toDate: date?.toDate,
          },
          headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // order
    builder.addCase(getOrderCount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getOrderCount.fulfilled, (state, action: PayloadAction<[]>) => {
      state.loading = false;
      state.orders = action.payload;
    });
    builder.addCase(getOrderCount.rejected, (state, action) => {
      state.loading = false;
      state.orders = [];
    });
    // sale
    builder.addCase(getSaleCount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSaleCount.fulfilled, (state, action: PayloadAction<[]>) => {
      state.loading = false;
      state.sales = action.payload;
    });
    builder.addCase(getSaleCount.rejected, (state, action) => {
      state.loading = false;
      state.sales = [];
    });
    // ItemQuantity
    builder.addCase(getItemQuantityCount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getItemQuantityCount.fulfilled, (state, action: PayloadAction<[]>) => {
      state.loading = false;
      state.ItemQuantity = action.payload;
    });
    builder.addCase(getItemQuantityCount.rejected, (state, action) => {
      state.loading = false;
      state.ItemQuantity = [];
    });
    // Refund
    builder.addCase(getRefundCount.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getRefundCount.fulfilled, (state, action: PayloadAction<[]>) => {
      state.loading = false;
      state.Refund = action.payload;
    });
    builder.addCase(getRefundCount.rejected, (state, action) => {
      state.loading = false;
      state.Refund = [];
    });
    // FulfillmentForward
    builder.addCase(getFulfillmentForward.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFulfillmentForward.fulfilled, (state, action: PayloadAction<[]>) => {
      state.loading = false;
      state.FulfillmentForward = action.payload;
    });
    builder.addCase(getFulfillmentForward.rejected, (state, action) => {
      state.loading = false;
      state.FulfillmentForward = [];
    });
    // FulfillmentBackward
    builder.addCase(getFulfillmentBackward.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFulfillmentBackward.fulfilled, (state, action: PayloadAction<[]>) => {
      state.loading = false;
      state.FulfillmentBackward = action.payload;
    });
    builder.addCase(getFulfillmentBackward.rejected, (state, action) => {
      state.loading = false;
      state.FulfillmentBackward = [];
    });
  },
});

export const thunks = {
  getOrderCount,
  getSaleCount,
  getItemQuantityCount,
  getFulfillmentForward,
  getFulfillmentBackward,
  getRefundCount,
};

export default dashboardSlice.reducer;
