import React from 'react';
import { useTranslation } from 'react-i18next';
import { PieChartCustomLegend } from '../../common/charts/PieChartCustomLegend';
import { healthChartData } from 'constants/healthChartData';
import { Card } from '@app/components/common/Card/Card';
import { useAppSelector } from '@app/hooks/reduxHooks';

export const RefundCard: React.FC = () => {
  const { t } = useTranslation();
  const { Refund } = useAppSelector((state) => state.dashboard);

  const orderChartData = [
    {
      value: Refund?.refund || 0,
      name: 'Pending Refund',
    },
    {
      value: Refund?.refunded || 0,
      name: 'Refund Initiated',
    },
  ];

  const chartData = orderChartData?.map((item) => ({
    ...item,
    name: t(item?.name),
  }));

  const legendData = chartData?.map((item) => ({ ...item, value: `${item.value}` }));

  return (
    <Card title={`${t('common.refund')}`} padding={'0 1.25rem 1.875rem'}>
      <PieChartCustomLegend
        name={t('common.order')}
        chartData={chartData || []}
        legendData={legendData || []}
        height={'150px'}
      />
    </Card>
  );
};
