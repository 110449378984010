import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ProductModel,ProductDetailModel, Product, BaseResponse } from '@app/domain/ProductModel';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { BlogModel } from '@app/domain/BlogModel';

export interface BlogState {
  loading: boolean;
  Blogs: BlogModel[] | [];
  Blog: BlogModel | null;

}

const initialState: BlogState = {
  loading: false,
  Blogs: [],
  Blog: null,
};

export const addBlog = createAsyncThunk(
  "/blog/add-blog",
  async (values, thunkApi) => {
    try {
      const response = await axios.post<BlogModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Blog/SaveBlog`,
        {...values, companyId: getLoggedInCompanyId()},
        {
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const BlogList = createAsyncThunk(
  "/blog/blog-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get<BlogModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Blog/GetAllBlogList`,
        {
          params: {
            companyId: getLoggedInCompanyId(),
          },
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const UpdateBlog = createAsyncThunk(
  "blog/update-blog",
  async (values, thunkApi) => {
    console.log(values);
    try {
      const response = await axios.put<BlogModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Blog/UpdateBlog`,
         {...values, companyId: getLoggedInCompanyId()},
        {
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const DeleteBlog = createAsyncThunk('blog/delete-blog', async (id, thunkApi) => {
  try {
    const response = await axios.delete<BlogModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Blog/DeleteBlog`,
      {
        params: {
          Id: id
        },
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
}
);

export const BlogDetails = createAsyncThunk(
  "blog/view-blog",
  async (id, thunkApi) => {
    try {
      const response = await axios.get<BlogModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Blog/GetBlogDetailsById`,{
          params: 
          {
            Id: id
          }
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);
  
const BlogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {},
  extraReducers: builder => {
      // list
      builder.addCase(BlogList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(BlogList.fulfilled,(state, action: PayloadAction<BlogModel[]>) => {
          state.loading = false;
          state.Blogs = action.payload;
      });
      builder.addCase(BlogList.rejected, (state, action) => {
        state.loading = false;
        state.Blogs = [];
      });
      builder.addCase(BlogDetails.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(BlogDetails.fulfilled,(state, action: PayloadAction<BlogModel>) => {
          state.loading = false;
          state.Blog = action.payload;
      });
      builder.addCase(BlogDetails.rejected, (state, action) => {
        state.loading = false;
        state.Blog = null;
      });
  }
})

export const thunks = {
    BlogList,
    BlogDetails,
  UpdateBlog,
  addBlog
};

export default BlogSlice.reducer;
