import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { VendorModel } from '@app/domain/VendorModel';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';

export interface VendorState {
  loading: boolean;
  vendors: VendorModel[] | [];
  vendorProduct: VendorModel[] | [];
  vendor: VendorModel | null;
}

const initialState: VendorState = {
  loading: false,
  vendors: [],
  vendorProduct: [],
  vendor: null,
};

export const vendorList = createAsyncThunk(
  "vendors/all-vendor",
  async (_, thunkApi) => {
    try {
      const response = await axios.get<VendorModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Seller/GetSellerList`,{
          params: {
            companyId: getLoggedInCompanyId(),
          },
          headers: {
            "Accept":"application/json",
            "Authorization": "Bearer " + readToken()
          }
        });
        console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const vendorProductList = createAsyncThunk(
  "vendors/all-vendorProduct",
  async (_, thunkApi) => {
    try {
      const response = await axios.get<VendorModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Seller/GetSellerProductList`,{
          params: {
            companyId: getLoggedInCompanyId(),
          },
          headers: {
            "Accept":"application/json",
            "Authorization": "Bearer " + readToken()
          }
        });
        console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const vendorDetail = createAsyncThunk(
  "vendors/vendor-detail",
  async (id, thunkApi) => {
    try {
      const response = await axios.get<VendorModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Seller/GetSellerDetailsById/?Id=${id}`,
        {
          headers: {
            "Accept":"application/json",
            "Authorization": "Bearer " + readToken()
          }
        }
      );
      console.log(response.data)
      
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const createVendor = createAsyncThunk(
  "vendors/onboard-vendor",
  async (values, thunkApi) => {
    try {
      const response = await axios.post<VendorModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Seller/SaveSeller`,
        {...values, companyId: getLoggedInCompanyId()}, 
        {
          headers: {
            "Accept":"application/json",
            "Authorization": "Bearer " + readToken()
          }
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const vendorUpdate = createAsyncThunk(
    "vendors/vendor-update",
    async (values, thunkApi) => {
      try {
        const response = await axios.put<VendorModel>(
          `${process.env.REACT_APP_BASE_URL}/Ecommerce/Seller/UpdateSeller`,
          {...values, companyId: getLoggedInCompanyId()}, {
            headers: {
              "Accept":"application/json",
              "Authorization": "Bearer " + readToken()
            }
          });
        return response.data;
      } catch (error) {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
      }
    }
  );
  
export const vendorDelete = createAsyncThunk(
  "vendors/vendor-delete",
  async (id, thunkApi) => {
    try {
      const response = await axios.delete<VendorModel>(
        `${process.env.REACT_APP_BASE_URL}/onboarding-app-backend/api/vendoronboards/${id}`, {
          headers: {
            "Accept":"application/json",
            "Authorization": "Bearer " + readToken()
          }
        });
      return response.data?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const deletevendor = createAsyncThunk('vendors/delete-vendors', async (id, thunkApi) => {
  try {
    const response = await axios.delete<VendorModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Seller/DeleteSeller`,
      {
        params: {
          Id: id
        },
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {},
  extraReducers: builder => {
      // list
      builder.addCase(vendorList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(vendorList.fulfilled,(state, action: PayloadAction<VendorModel[]>) => {
          state.loading = false;
          state.vendors = action.payload;
      });
      builder.addCase(vendorList.rejected, (state, action) => {
        state.loading = false;
        state.vendors = [];
      });
      builder.addCase(vendorProductList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(vendorProductList.fulfilled,(state, action: PayloadAction<VendorModel[]>) => {
          state.loading = false;
          state.vendorProduct = action.payload;
      });
      builder.addCase(vendorProductList.rejected, (state, action) => {
        state.loading = false;
        state.vendorProduct = [];
      });
      // Detail
      builder.addCase(vendorDetail.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(vendorDetail.fulfilled,(state, action: PayloadAction<VendorModel>) => {
          state.loading = false;
          state.vendor = action.payload;
      });
      builder.addCase(vendorDetail.rejected, (state, action) => {
        state.loading = false;
        state.vendor = null;
      });
      // Save 
      builder.addCase(createVendor.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(createVendor.fulfilled,(state, action: PayloadAction<VendorModel>) => {
          state.loading = false;
          state.vendors = action.payload.data;
      });
      builder.addCase(createVendor.rejected, (state, action) => {
        state.loading = false;
        state.vendors = [];
      });
      // Update 
      builder.addCase(vendorUpdate.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(vendorUpdate.fulfilled,(state, action: PayloadAction<VendorModel>) => {
          state.loading = false;
      });
      builder.addCase(vendorUpdate.rejected, (state, action) => {
        state.loading = false;
      });
      // Delete
      builder.addCase(vendorDelete.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(vendorDelete.fulfilled,(state, action: PayloadAction<VendorModel>) => {
          state.loading = false;
          state.vendor = action.payload;
      });
      builder.addCase(vendorDelete.rejected, (state, action) => {
        state.loading = false;
        state.vendor = null;
      });
  }
})

export const thunks = {
  vendorList,
  vendorProductList,
  vendorDetail,
  createVendor,
  vendorUpdate,
  vendorDelete,
};

export default vendorSlice.reducer;
