export enum ImageEndPointPrefix {
  CATEGORY = '/Ecommerce/Category/Image/',
  SUB_CATEGORY = '/Ecommerce/SubCategory/Image/',
  PRODUCT = '/Ecommerce/Product/',
  VARIANT = '/Variant/',
  IMAGE = '/Image/',
  BRAND = '/Ecommerce/Brand/Image/',
  PROMOTION = '/Ecommerce/Promotion/Image/',
  PROMOTION_BANNER = '/Ecommerce/PromotionBanner/Image/',
  BLOG = '/Ecommerce/Blog/Image/',
  COMPANY = '/Ecommerce/Companies/Image/',
  OGIMAGE= '/Ecommerce/Companies/OgImage/',
  FAVICON= '/Ecommerce/Companies/FavIconImage/'
}
