import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { Order } from '@app/domain/OrderModel';
import { OrderListTableRow, ProductOrder } from '@app/api/table.api';

export interface OrderState {
  loading: boolean;
  Orders: Order[] | [];
  Order: Order | null;
  CustomerOrderDetail: OrderListTableRow | null;
}

const initialState: OrderState = {
  loading: false,
  Orders: [],
  Order: null,
  CustomerOrderDetail: null,
};

export const orderList = createAsyncThunk('/order/order-list', async (_, thunkApi) => {
  try {
    const response = await axios.get<Order[]>(`${process.env.REACT_APP_BASE_URL}/Ecommerce/Order/GetOrders`, {
      params: {
        companyId: getLoggedInCompanyId(),
      },
      headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const customerOrderDetails = createAsyncThunk('order/customer-detail', async (orderId, thunkApi) => {
  try {
    const response = await axios.get<OrderListTableRow>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Order/GetCustomerOrderDetails/orderId/${orderId}`,
      {
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const updateRefundAmountbyOrderId = createAsyncThunk('order/update-refundAmount', async (orderId, thunkApi) => {
  try {
    const response = await axios.put<ProductOrder>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Order/UpdatOrderRefundAmountByorderId/orderId/${orderId}`,
      {
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const updatePaymentStatusByOrderId = createAsyncThunk('order/update-PaymentStatus', async (orderId, thunkApi) => {
  try {
    console.log('axios');
    const response = await axios.put<ProductOrder>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Order/Update/PaymentStatus/OrderId/${orderId}`,
      {
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(orderList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(orderList.fulfilled, (state, action: PayloadAction<Order[]>) => {
      state.loading = false;
      state.Orders = action.payload;
    });
    builder.addCase(orderList.rejected, (state, action) => {
      state.loading = false;
      state.Orders = [];
    });
    builder.addCase(customerOrderDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(customerOrderDetails.fulfilled, (state, action: PayloadAction<OrderListTableRow>) => {
      state.loading = false;
      state.CustomerOrderDetail = action.payload;
    });
    builder.addCase(customerOrderDetails.rejected, (state, action) => {
      state.loading = false;
      state.CustomerOrderDetail = null;
    });
    builder.addCase(updateRefundAmountbyOrderId.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateRefundAmountbyOrderId.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateRefundAmountbyOrderId.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updatePaymentStatusByOrderId.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updatePaymentStatusByOrderId.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updatePaymentStatusByOrderId.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const thunks = {
  orderList,
};

export default orderSlice.reducer;
