export const MasterType = {
  VARIANT_TYPE: 'VariantType' as const,
  ADDRESS_TYPE: 'AddressType' as const,
  PAYMENT_PROVIDER: 'PaymentProvider' as const,
  PAYMENT_STATUS: 'PaymentStatus' as const,
  PAYMENT_TYPE: 'PaymentType' as const,
  OFFERS_METHOD: 'OffersMethod' as const,
  OFFER_APPLY_TYPE: 'OfferApplyType' as const,
  OFFER_EXPIRED_BY: 'OfferExpiredBy' as const,
  YN_STATUS: 'YNStatus' as const,
};

type MasterTypeValues = keyof typeof MasterType;
type MasterTypeValue = typeof MasterType[MasterTypeValues];