import React, { useEffect, useState } from 'react';
import { Card, Col, DatePicker, Row } from 'antd';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { References } from '@app/components/common/References/References';
import { useResponsive } from '@app/hooks/useResponsive';
import { TrendingCreators } from '@app/components/nft-dashboard/trending-creators/TrendingCreators';
import { RecentlyAddedNft } from '@app/components/nft-dashboard/recently-added/RecentlyAddedNft';
import { TrendingCollections } from '@app/components/nft-dashboard/trending-collections/TrendingCollections';
import { Balance } from '@app/components/nft-dashboard/Balance/Balance';
import { TotalEarning } from '@app/components/nft-dashboard/totalEarning/TotalEarning';
import { ActivityStory } from '@app/components/nft-dashboard/activityStory/ActivityStory';
import { RecentActivity } from '@app/components/nft-dashboard/recentActivity/RecentActivity';
import * as S from './DashboardPage.styles';
import { HealthCard } from '@app/components/medical-dashboard/HealthCard/HealthCard';
import { useDispatch } from 'react-redux';
import {
  getFulfillmentBackward,
  getFulfillmentForward,
  getItemQuantityCount,
  getOrderCount,
  getRefundCount,
} from '@app/store/slices/dashboardSlice';
import WeekWiseChart from '@app/components/medical-dashboard/weekWiseChart/WeekWiseChart';
import { ReactComponent as ProteinIcon } from '@app/assets/icons/protein.svg';
import { ReactComponent as WaterBalanceIcon } from '@app/assets/icons/water.svg';
import { StatisticsCard } from '@app/components/medical-dashboard/statisticsCards/statisticsCard/StatisticsCard/StatisticsCard';
import { ItemQuantityCard } from '@app/components/medical-dashboard/ItemQuantityCard/ItemQuantityCard';
import { RefundCard } from '@app/components/medical-dashboard/RefundCard/RefundCard';
import OrderCard from '@app/components/orderCard/orderCard';
import newIcon from '../../assets/images/newIcon.png';
import progressIcon from '../../assets/images/progressIcon.jpg';
import cancelledIcon from '../../assets/images/cancelledIcon.png';
import { FulfillmentBackCard } from '@app/components/medical-dashboard/FulfillmentBackCard/FulfillmentBackCard';
import { useAppSelector } from '@app/hooks/reduxHooks';
import moment, { Moment } from 'moment';

const { RangePicker } = DatePicker;

const MedicalDashboardPage: React.FC = () => {
  const { isDesktop } = useResponsive();
  const dispatch = useDispatch();
  const { orders } = useAppSelector((state) => state.dashboard);
  const currentDate = moment();
  const [selectedDates, setSelectedDates] = useState<Moment[]>([currentDate.clone(), currentDate.clone()]);
  const [fromDate, setFromDate] = useState<string | null>(currentDate.format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState<string | null>(currentDate.format('YYYY-MM-DD'));

  useEffect(() => {
    dispatch(getOrderCount({ fromDate, toDate }));
    dispatch(getItemQuantityCount({ fromDate, toDate }));
    dispatch(getRefundCount({ fromDate, toDate }));
    dispatch(getFulfillmentForward({ fromDate, toDate }));
    dispatch(getFulfillmentBackward({ fromDate, toDate }));
  }, [fromDate, toDate]);

  const handleDateChange = (dates: Moment[]) => {
    setSelectedDates(dates);
    setFromDate(dates[0]?.format('YYYY-MM-DD') || null);
    setToDate(dates[1]?.format('YYYY-MM-DD') || null);
  };

  const desktopLayout = (
    <div style={{ padding: 25 }}>
      {/* <S.LeftSideCol xl={16} xxl={17} id="desktop-content">
        <Row gutter={[60, 60]}>
          <Col span={24}>
            <TrendingCreators />
          </Col>

          <Col span={24}>
            <RecentlyAddedNft />
          </Col>

          <Col span={24}>
            <TrendingCollections />
          </Col>

          <Col span={24}>
            <RecentActivity />
          </Col>
        </Row>
        <References />
      </S.LeftSideCol>

      <S.RightSideCol xl={8} xxl={7}>
        <div id="balance">
          <Balance />
        </div>
        <S.Space />
        <div id="total-earning">
          <TotalEarning />
        </div>
        <S.Space />
        <S.ScrollWrapper id="activity-story">
          <ActivityStory />
        </S.ScrollWrapper>
      </S.RightSideCol> */}
      <Row gutter={{ xs: 10, md: 15, xl: 30 }} style={{ paddingBottom: 15, paddingTop: 15 }}>
        <Col span={18}>
          <WeekWiseChart />
        </Col>
        <Col span={6}>
          <Col xs={24} style={{ padding: 5 }}>
            <Card>
              <RangePicker size="small" style={{ width: '100%' }} onChange={handleDateChange} value={selectedDates} />
            </Card>
          </Col>
          <Col xs={24} style={{ padding: 5 }}>
            <OrderCard name="New Order" value={orders?.new || 0} color="blue" imageUrl={newIcon} />
          </Col>
          <Col xs={24} style={{ padding: 5 }}>
            <OrderCard name="Progress Order" value={orders?.inProcess || 0} color="#f8d000" imageUrl={progressIcon} />
          </Col>
          <Col xs={24} style={{ padding: 5 }}>
            <OrderCard name="Closed Order" value={orders?.closed || 0} color="red" imageUrl={cancelledIcon} />
          </Col>
        </Col>
      </Row>
      <Row gutter={{ xs: 10, md: 15, xl: 30 }} style={{ paddingBottom: 15, paddingTop: 15 }}>
        <Col span={6}>
          <ItemQuantityCard />
        </Col>
        <Col span={6}>
          <HealthCard />
        </Col>
        <Col span={6}>
          <FulfillmentBackCard />
        </Col>
        <Col span={6}>
          <RefundCard />
        </Col>
      </Row>
    </div>
  );

  const mobileAndTabletLayout = (
    <Row gutter={[20, 24]}>
      <Col span={24}>
        <WeekWiseChart />
      </Col>

      <Col span={24}>
        <HealthCard />
      </Col>

      {/* <Col span={24}>
        <TrendingCollections />
      </Col>

      <Col span={24}>
        <RecentActivity />
      </Col> */}
    </Row>
  );

  return (
    <>
      <PageTitle>Seller App</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default MedicalDashboardPage;
