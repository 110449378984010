import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ProductModel,ProductDetailModel, Product, BaseResponse } from '@app/domain/ProductModel';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { SubCategoryModel } from '@app/domain/CategoryModel';

export interface SubCategoryState {
  loading: boolean;
  SubCategorys: SubCategoryModel[] | [];
  SubCatProduct: SubCategoryModel[] | [];
  SubCatOrder: SubCategoryModel[] | [];
  SubCategory: SubCategoryModel | null;

}

const initialState: SubCategoryState = {
  loading: false,
  SubCategorys: [],
  SubCatProduct:[],
  SubCatOrder:[],
  SubCategory: null,
};


export const subcategoryList = createAsyncThunk(
  "/category/subcategory-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get<SubCategoryModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/SubCategory/GetAllSubCategoryList`,
        {
          params: {
          companyId: getLoggedInCompanyId(),
        },
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const subcategoryProductList = createAsyncThunk(
  "/category/subcategoryProduct-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get<SubCategoryModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/SubCategory/GetAllSubCategoryProductList`,
        {
          params: {
          companyId: getLoggedInCompanyId(),
        },
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const subcategoryOrderList = createAsyncThunk(
  "/category/subcategoryOrder-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get<SubCategoryModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/SubCategory/GetAllSubCategoryOrderList`,
        {
          params: {
          companyId: getLoggedInCompanyId(),
        },
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const addSubCategory = createAsyncThunk('subCategory/add-subCategory', async (values, thunkApi) => {
  try {
    const response = await axios.post<SubCategoryModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/SubCategory/SaveSubCategory`,
      {...values, companyId: getLoggedInCompanyId()},
      {
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const UpdateSubCategory = createAsyncThunk(
  "category/update-category",
  async (values, thunkApi) => {
    try {
      const response = await axios.put<SubCategoryModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/SubCategory/UpdateSubCategory`, {...values, companyId: getLoggedInCompanyId()},
        {
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const subCategoryDetails = createAsyncThunk(
  "products/view-product",
  async (id, thunkApi) => {
    try {
      const response = await axios.get<SubCategoryModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/SubCategory/GetSubCategoryDetailsById`,
        {
          params: 
          {
            Id: id
          }
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);


export const deleteSubCategory = createAsyncThunk('subcategory/delete-subcategory', async (id, thunkApi) => {
  try {
    const response = await axios.delete<SubCategoryModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/SubCategory/DeleteSubCategory`,
      {
        params: {
          Id: id
        },
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const SubCategorySlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: builder => {
      // list
      builder.addCase(subcategoryList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(subcategoryList.fulfilled,(state, action: PayloadAction<SubCategoryModel[]>) => {
          state.loading = false;
          state.SubCategorys = action.payload;
      });
      builder.addCase(subcategoryList.rejected, (state, action) => {
        state.loading = false;
        state.SubCategorys = [];
      });
      builder.addCase(subcategoryProductList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(subcategoryProductList.fulfilled,(state, action: PayloadAction<SubCategoryModel[]>) => {
          state.loading = false;
          state.SubCatProduct = action.payload;
      });
      builder.addCase(subcategoryProductList.rejected, (state, action) => {
        state.loading = false;
        state.SubCatProduct = [];
      });
      builder.addCase(subcategoryOrderList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(subcategoryOrderList.fulfilled,(state, action: PayloadAction<SubCategoryModel[]>) => {
          state.loading = false;
          state.SubCatOrder = action.payload;
      });
      builder.addCase(subcategoryOrderList.rejected, (state, action) => {
        state.loading = false;
        state.SubCatOrder = [];
      });
      builder.addCase(subCategoryDetails.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(subCategoryDetails.fulfilled,(state, action: PayloadAction<SubCategoryModel>) => {
          state.loading = false;
          state.SubCategory = action.payload;
      });
      builder.addCase(subCategoryDetails.rejected, (state, action) => {
        state.loading = false;
        state.SubCategory = null;
      });
  }
})

export const thunks = {
  subcategoryList,
  subcategoryOrderList
};

export default SubCategorySlice.reducer;
