import React, { useState, useEffect } from 'react'; // If you are using React
import { Line } from '@ant-design/plots';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '@app/components/common/Card/Card';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getSaleCount } from '@app/store/slices/dashboardSlice';

const { Option } = Select;

function WeekWiseChart() {
  const { t } = useTranslation();
  const [selectedData, setSelectedData] = useState([]);
  const [weekSelector, setWeekSelector] = useState('TW');

  const { sales } = useAppSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSaleCount(weekSelector));
  }, [weekSelector]);

  useEffect(() => {
    if (sales?.length) {
      setSelectedData(sales);
    }
  }, [sales]);

  return (
    <Card title={t('common.sales')} padding={'0 1.25rem 1.875rem'}>
      <div>
        <div>
          <Line
            data={selectedData}
            xField="day"
            yField="value"
            seriesField="type"
            height={350}
            width={600}
            title={{
              text: weekSelector === 'this-week' ? 'This Week Line Chart' : 'Last Week Line Chart',
            }}
          />
        </div>
        <div style={{ float: 'right', marginTop: '-375px' }}>
          <Select value={weekSelector} onChange={(value) => setWeekSelector(value)} size="small">
            <Option value="TW">This Week</Option>
            <Option value="LW">Last Week</Option>
            <Option value="TM">This Month</Option>
            <Option value="LM">Last Month</Option>
          </Select>
        </div>
      </div>
    </Card>
  );
}
export default WeekWiseChart;
