import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { PaymentProviderModel } from '@app/domain/PaymentProviderModel';
import axios from 'axios';
import { getLoggedInCompanyId, readToken, readUser } from '@app/services/localStorage.service';
import { VendorModel } from '@app/domain/VendorModel';
import { PaymentSetupModel } from '@app/domain/PaymentSetupModel';

export interface PaymentProviderState {
  loading: boolean;
  paymentProviders: PaymentProviderModel[] | [];
  paymentProvider: PaymentProviderModel | null;
}

const initialState: PaymentProviderState = {
  loading: false,
  paymentProviders: [],
  paymentProvider: null,
};

export const paymentProviderList = createAsyncThunk('paymentProviders/all-paymentProvider', async (_, thunkApi) => {
  try {
    const response = await axios.get<PaymentProviderModel[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/PaymentProviders/GetAllPaymentProvidersList`,
      {
        params: {
          companyId: getLoggedInCompanyId(),
        },
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + readToken(),
        },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const paymentProviderDetail = createAsyncThunk(
  'paymentProviders/paymentProvider-detail',
  async (id, thunkApi) => {
    try {
      const response = await axios.get<PaymentProviderModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/PaymentProviders/GetPaymentProvidersDetailsById`,
        {
          params: {
            id: id,
          },
        },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);
export const createPaymentProvider = createAsyncThunk(
  'paymentProviders/paymentProvider-request-form',
  async (values, thunkApi) => {
    try {
      const response = await axios.post<PaymentProviderModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/PaymentProviders/SavePaymentProviders`,
        { ...values, currentUserId: readUser()?.userId, companyId: getLoggedInCompanyId() },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + readToken(),
          },
        },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);
export const paymentProviderUpdate = createAsyncThunk(
  'paymentProviders/paymentProvider-update',
  async (values, thunkApi) => {
    try {
      const response = await axios.put<PaymentProviderModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/PaymentProviders/UpdatePaymentProviders`,
        { ...values, companyId: getLoggedInCompanyId() },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + readToken(),
          },
        },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);
export const paymentProviderDelete = createAsyncThunk(
  'paymentProviders/paymentProvider-delete',
  async (ids, thunkApi) => {
    try {
      const response = await axios.delete<PaymentProviderModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/PaymentProviders/DeletePaymentProviders`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + readToken(),
          },
          params: {
            ids: ids,
          },
        },
      );
      return response.data?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);

export const paymentDetailsUpdate = createAsyncThunk(
  'payment/paymentDetails-update',
  async (values, thunkApi) => {
    try {
      const response = await axios.put<PaymentSetupModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/PaymentProviders/UpdatePaymentSetupDetails`,
        { ...values, companyId: getLoggedInCompanyId() },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + readToken(),
          },
        },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);

const paymentProviderSlice = createSlice({
  name: 'PaymentProvider',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(paymentProviderList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(paymentProviderList.fulfilled, (state, action: PayloadAction<PaymentProviderModel[]>) => {
      state.loading = false;
      state.paymentProviders = action.payload;
    });
    builder.addCase(paymentProviderList.rejected, (state, action) => {
      state.loading = false;
      state.paymentProviders = [];
    });
    // Detail
    builder.addCase(paymentProviderDetail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(paymentProviderDetail.fulfilled, (state, action: PayloadAction<PaymentProviderModel>) => {
      state.loading = false;
      state.paymentProvider = action.payload;
    });
    builder.addCase(paymentProviderDetail.rejected, (state, action) => {
      state.loading = false;
      state.paymentProvider = null;
    });
    // Save
    builder.addCase(createPaymentProvider.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createPaymentProvider.fulfilled, (state, action: PayloadAction<AddPaymentProvider>) => {
      state.loading = false;
      state.paymentProvider = action.payload;
    });
    builder.addCase(createPaymentProvider.rejected, (state, action) => {
      state.loading = false;
      state.paymentProviders = [];
    });
    // Update
    builder.addCase(paymentProviderUpdate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(paymentProviderUpdate.fulfilled, (state, action: PayloadAction<PaymentProviderModel>) => {
      state.loading = false;
      state.paymentProvider = action.payload;
    });
    builder.addCase(paymentProviderUpdate.rejected, (state, action) => {
      state.loading = false;
      state.paymentProvider = null;
    });
    // Delete
    builder.addCase(paymentProviderDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(paymentProviderDelete.fulfilled, (state, action: PayloadAction<PaymentProviderModel>) => {
      state.loading = false;
      state.paymentProvider = action.payload;
    });
    builder.addCase(paymentProviderDelete.rejected, (state, action) => {
      state.loading = false;
      state.paymentProvider = null;
    });
  },
});

export const thunks = {
  paymentProviderList,
  paymentProviderDetail,
  createPaymentProvider,
  paymentProviderUpdate,
  paymentProviderDelete,
};

export default paymentProviderSlice.reducer;
