import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { MessageProvidersModel } from '@app/domain/MessageProvidersModel';

export interface MessageProvidersState {
  loading: boolean;
  MessageProviders: MessageProvidersModel[] | [];
  MessageProvider: MessageProvidersModel | null;

}

const initialState: MessageProvidersState = {
  loading: false,
  MessageProviders: [],
  MessageProvider: null,
};

export const addMessageProviders = createAsyncThunk(
  "/messageProviders/add-messageProviders",
  async (values, thunkApi) => {
    try {
      const response = await axios.post<MessageProvidersModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/MessageProviders/SaveMessageProviders`,
        {...values, companyId: getLoggedInCompanyId()},
        {
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const MessageProvidersList = createAsyncThunk(
  "/messageProviders/messageProviders-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get<MessageProvidersModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/MessageProviders/GetAllMessageProvidersList`,
        {
          params: {
            companyId: getLoggedInCompanyId(),
          },
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const UpdateMessageProviders = createAsyncThunk(
  "messageProviders/update-messageProviders",
  async (values, thunkApi) => {
    console.log(values);
    try {
      const response = await axios.put<MessageProvidersModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/MessageProviders/UpdateMessageProviders`,
         {...values, companyId: getLoggedInCompanyId()},
        {
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const MessageProvidersDetails = createAsyncThunk(
  "messageProviders/view-messageProviders",
  async (id, thunkApi) => {
    try {
      const response = await axios.get<MessageProvidersModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/MessageProviders/GetMessageProvidersDetailsById`,{
          params: 
          {
            Id: id,
            companyId: getLoggedInCompanyId(),
          }
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const DeleteMessageProviders = createAsyncThunk('messageProviders/delete-messageProviders', async (id, thunkApi) => {
  try {
    const response = await axios.delete<MessageProvidersModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/MessageProviders/DeleteMessageProviders`,
      {
        params: {
          Id: id
        },
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});
  
const MessageProvidersSlice = createSlice({
  name: 'messageProviders',
  initialState,
  reducers: {},
  extraReducers: builder => {
      // list
      builder.addCase(MessageProvidersList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(MessageProvidersList.fulfilled,(state, action: PayloadAction<MessageProvidersModel[]>) => {
          state.loading = false;
          state.MessageProviders = action.payload;
      });
      builder.addCase(MessageProvidersList.rejected, (state, action) => {
        state.loading = false;
        state.MessageProviders = [];
      });
      builder.addCase(MessageProvidersDetails.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(MessageProvidersDetails.fulfilled,(state, action: PayloadAction<MessageProvidersModel>) => {
          state.loading = false;
          state.MessageProvider = action.payload;
      });
      builder.addCase(MessageProvidersDetails.rejected, (state, action) => {
        state.loading = false;
        state.MessageProvider = null;
      });
  }
})

export const thunks = {
  MessageProvidersList,
  MessageProvidersDetails,
  UpdateMessageProviders,
  addMessageProviders
};

export default MessageProvidersSlice.reducer;
