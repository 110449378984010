import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ProductModel,ProductDetailModel, Product, BaseResponse } from '@app/domain/ProductModel';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { PromotionBannerModel } from '@app/domain/PromotionBannerModel';

export interface PromotionBannerState {
  loading: boolean;
  PromotionBanners: PromotionBannerModel[] | [];
  PromotionBanner: PromotionBannerModel | null;

}

const initialState: PromotionBannerState = {
  loading: false,
  PromotionBanners: [],
  PromotionBanner: null,
};

export const addPromotionBanner = createAsyncThunk(
  "/promotionBanner/add-promotionBanner",
  async (values, thunkApi) => {
    try {
      const response = await axios.post<PromotionBannerModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/PromotionBanner/SavePromotionBanner`,
        {...values, companyId: getLoggedInCompanyId()},
        {
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const PromotionBannerList = createAsyncThunk(
  "/promotionBanner/promotionBanner-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get<PromotionBannerModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/PromotionBanner/GetAllPromotionBannerList`,
        {
          params: {
            companyId: getLoggedInCompanyId(),
          },
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const UpdatePromotionBanner = createAsyncThunk(
  "promotionBanner/update-promotionBanner",
  async (values, thunkApi) => {
    console.log(values);
    try {
      const response = await axios.put<PromotionBannerModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/PromotionBanner/UpdatePromotionBanner`,
         {...values, companyId: getLoggedInCompanyId()},
        {
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const DeletePromotionBanner = createAsyncThunk('promotionBanner/delete-promotionBanner', async (id, thunkApi) => {
  try {
    const response = await axios.delete<PromotionBannerModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/PromotionBanner/DeletePromotionBanner`,
      {
        params: {
          Id: id
        },
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
}
);

export const PromotionBannerDetails = createAsyncThunk(
  "promotionBanner/view-promotionBanner",
  async (id, thunkApi) => {
    try {
      const response = await axios.get<PromotionBannerModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/PromotionBanner/GetPromotionBannerDetailsById`,{
          params: 
          {
            Id: id
          }
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);
  
const PromotionBannerSlice = createSlice({
  name: 'promotionBanner',
  initialState,
  reducers: {},
  extraReducers: builder => {
      // list
      builder.addCase(PromotionBannerList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(PromotionBannerList.fulfilled,(state, action: PayloadAction<PromotionBannerModel[]>) => {
          state.loading = false;
          state.PromotionBanners = action.payload;
      });
      builder.addCase(PromotionBannerList.rejected, (state, action) => {
        state.loading = false;
        state.PromotionBanners = [];
      });
      builder.addCase(PromotionBannerDetails.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(PromotionBannerDetails.fulfilled,(state, action: PayloadAction<PromotionBannerModel>) => {
          state.loading = false;
          state.PromotionBanner = action.payload;
      });
      builder.addCase(PromotionBannerDetails.rejected, (state, action) => {
        state.loading = false;
        state.PromotionBanner = null;
      });
  }
})

export const thunks = {
  PromotionBannerList,
  PromotionBannerDetails,
  UpdatePromotionBanner,
  addPromotionBanner
};

export default PromotionBannerSlice.reducer;
