import React from 'react';
import { useTranslation } from 'react-i18next';
import { PieChartCustomLegend } from '../../common/charts/PieChartCustomLegend';
import { healthChartData } from 'constants/healthChartData';
import { Card } from '@app/components/common/Card/Card';
import { useAppSelector } from '@app/hooks/reduxHooks';

export const FulfillmentBackCard: React.FC = () => {
  const { t } = useTranslation();
  const { FulfillmentBackward } = useAppSelector((state) => state.dashboard);

  const orderChartData = [
    {
      value: FulfillmentBackward?.request || 0,
      name: 'Request',
    },
    {
      value: FulfillmentBackward?.approved || 0,
      name: ' Pending Return',
    },
    {
      value: FulfillmentBackward?.rejected || 0,
      name: ' Rejected',
    },
    {
      value: FulfillmentBackward?.return || 0,
      name: 'Returned',
    },
  ];

  const chartData = orderChartData?.map((item) => ({
    ...item,
    name: t(item?.name),
  }));

  const legendData = chartData?.map((item) => ({ ...item, value: `${item.value}` }));

  return (
    <Card title="Fulfillment Returned" padding={'0 1rem 1rem'}>
      <PieChartCustomLegend
        name={t('common.order')}
        chartData={chartData || []}
        legendData={legendData || []}
        height={'150px'}
      />
    </Card>
  );
};
