import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { BrandModel } from '@app/domain/BrandModel';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';

export interface BrandState {
  loading: boolean;
  brands: BrandModel[] | [];
  brand: BrandModel | null;
}

const initialState: BrandState = {
  loading: false,
  brands: [],
  brand: null,
};

export const brandList = createAsyncThunk('/brand/brand-list', async (_, thunkApi) => {
  try {
    const response = await axios.get<BrandModel[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Brand/GetAllBrandList`,
      {
        params: {
          companyId: getLoggedInCompanyId(),
        },
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const addBrand = createAsyncThunk('brand/add-brand', async (values, thunkApi) => {
  try {
    const response = await axios.post<BrandModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Brand/SaveBrand`,
      {...values, companyId: getLoggedInCompanyId()},
      {
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const UpdateBrand = createAsyncThunk('brand/Update-brand', async (values, thunkApi) => {
  console.log(values);
  try {
    const response = await axios.put<BrandModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Brand/UpdateBrand`,
      {...values, companyId: getLoggedInCompanyId()},
      {
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const deletebrand = createAsyncThunk('brand/delete-brand', async (id, thunkApi) => {
  try {
    const response = await axios.delete<BrandModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Brand/DeleteBrand`,
      {
        params: {
          Id: id
        },
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const BrandDetails = createAsyncThunk('brand/view-brand', async (id, thunkApi) => {
  try {
    const response = await axios.get<BrandModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Brand/GetBrandDetailsById`,
      {
        params: {
          Id: id,
        },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(brandList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(brandList.fulfilled, (state, action: PayloadAction<BrandModel[]>) => {
      state.loading = false;
      state.brands = action.payload;
    });
    builder.addCase(brandList.rejected, (state, action) => {
      state.loading = false;
      state.brands = [];
    });
    // details
    builder.addCase(BrandDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(BrandDetails.fulfilled, (state, action: PayloadAction<BrandModel>) => {
      state.loading = false;
      state.brand = action.payload;
    });
    builder.addCase(BrandDetails.rejected, (state, action) => {
      state.loading = false;
      state.brand = null;
    });
  },
});

export const thunks = {
  brandList,
  BrandDetails,
};

export default brandSlice.reducer;
