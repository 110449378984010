import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { FooterSectionModel } from '@app/domain/FooterModel';

export interface FooterSectionState {
  loading: boolean;
  FooterSections: FooterSectionModel[] | [];
  FooterSection: FooterSectionModel | null;

}

const initialState: FooterSectionState = {
  loading: false,
  FooterSections: [],
  FooterSection: null,
};

export const footerSectionList = createAsyncThunk(
  "footersection-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get<FooterSectionModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/FooterSection/GetAllFooterSectionList`,
        {
          params: {
          companyId: getLoggedInCompanyId(),
        },
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const addFooterSection = createAsyncThunk('/category/footersection-list', async (values, thunkApi) => {
  try {
    const response = await axios.post<FooterSectionModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/FooterSection/SaveFooterSection`,
      {...values,companyId: getLoggedInCompanyId()},
      {
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const UpdateFooterSection = createAsyncThunk(
  "footerSection/update-footerSection",
  async (values, thunkApi) => {
    try {
      const response = await axios.put<FooterSectionModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/FooterSection/UpdateFooterSection`, 
        {...values,companyId: getLoggedInCompanyId()},
        {
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const DeleteFooterSection = createAsyncThunk('footerSection/delete-footerSection', async (id, thunkApi) => {
  try {
    const response = await axios.delete<FooterSectionModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/FooterSection/DeleteFooterSection`,
      {
        params: {
          Id: id
        },
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const footerSectionDetails = createAsyncThunk(
  "footerSection/view-footerSection",
  async (id, thunkApi) => {
    try {
      const response = await axios.get<FooterSectionModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/FooterSection/GetFooterSectionDeatilsById`,
        {
          params: 
          {
            Id: id
          }
        }
      );
      console.log(response,'response');
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

const FooterSectionSlice = createSlice({
  name: 'FooterSection',
  initialState,
  reducers: {},
  extraReducers: builder => {
      // list
      builder.addCase(footerSectionList.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(footerSectionList.fulfilled,(state, action: PayloadAction<FooterSectionModel[]>) => {
          state.loading = false;
          state.FooterSections = action.payload;
      });
      builder.addCase(footerSectionList.rejected, (state, action) => {
        state.loading = false;
        state.FooterSections = [];
      });
      builder.addCase(footerSectionDetails.pending, (state, action )=> {
        state.loading = true;
      });
      builder.addCase(footerSectionDetails.fulfilled,(state, action: PayloadAction<FooterSectionModel>) => {
          state.loading = false;
          state.FooterSection = action.payload;
      });
      builder.addCase(footerSectionDetails.rejected, (state, action) => {
        state.loading = false;
        state.FooterSection = null;
      });
  }
})

export const thunks = {
  footerSectionList
};

export default FooterSectionSlice.reducer;
