import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CompanyModel } from '@app/domain/CompanyModel';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';

export interface CompanyState {
  loading: boolean;
  companies: CompanyModel[] | [];
  company: CompanyModel | null;
}

const initialState: CompanyState = {
  loading: false,
  companies: [],
  company: null,
};

export const companyList = createAsyncThunk('/company/company-list', async (_, thunkApi) => {
  try {
    const response = await axios.get<CompanyModel[]>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Companies/GetAllCompaniesList`,
      {
        params: {
          companyId: getLoggedInCompanyId(),
        },
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});


export const addCompany = createAsyncThunk('company/add-company', async (values, thunkApi) => {
  try {
    console.log('values', values);
    const response = await axios.post<CompanyModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Companies/SaveCompanies`,
      {...values, companyId: getLoggedInCompanyId()},
      {
        headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
      },
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

export const updateCompany = createAsyncThunk(
  "company/Update-company",
  async (values, thunkApi) => {
    console.log(values);
    try {
      const response = await axios.put<CompanyModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Companies/UpdateCompanies`, 
        {...values, companyId: getLoggedInCompanyId()},
        {
          headers: { "X-Auth-Token": "Bearer " + readToken() },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const CompanyDetails = createAsyncThunk(
  "company/view-company",
  async (id, thunkApi) => {
    try {
      const response = await axios.get<CompanyModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/Companies/GetCompaniesDetailsById`,{
          params: 
          {
            Id: id
          }
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const companyVerifyOtp = createAsyncThunk('company/add-company', async (values, thunkApi) => {
  try {
    const response = await axios.get<CompanyModel>(
      `${process.env.REACT_APP_BASE_URL}/Ecommerce/Companies/Verify/Otp`,{
        params: 
        {
          Email: values.email,
          Otp: values.otp
        }
      }
    );
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(companyList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(companyList.fulfilled, (state, action: PayloadAction<CompanyModel[]>) => {
      state.loading = false;
      state.companies = action.payload;
    });
    builder.addCase(companyList.rejected, (state, action) => {
      state.loading = false;
      state.companies = [];
    });
   // details
    builder.addCase(CompanyDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(CompanyDetails.fulfilled, (state, action: PayloadAction<CompanyModel>) => {
      state.loading = false;
      state.company = action.payload;
    });
    builder.addCase(CompanyDetails.rejected, (state, action) => {
      state.loading = false;
      state.company =null;
    });
  },
});

export const thunks = {
  companyList,
  CompanyDetails,
};

export default companySlice.reducer;
