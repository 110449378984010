import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doSignUp } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { ReactComponent as GoogleIcon } from '@app/assets/icons/google.svg';
import { ReactComponent as FacebookIcon } from '@app/assets/icons/facebook.svg';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpForm.styles';
import { Button, Form, Input, Alert, Col, Row, Select } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import { MailOutlined } from '@ant-design/icons';
import { addCompany, companyVerifyOtp } from '@app/store/slices/companySlice';

interface SignUpFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const initValues = {
  firstName: 'Chris',
  lastName: 'Johnson',
  email: 'chris.johnson@altence.com',
  password: 'test-pass',
  confirmPassword: 'test-pass',
  termOfUse: true,
};

const rules = {
  email: [
    {
      required: true,
      message: 'Please input your email address',
    },
    {
      type: 'email',
      message: 'Please enter a validate email!',
    },
  ],
  mobile: [
    {
      required: true,
      message: 'Please input your mobile number',
    },
    {
      pattern: /^\d{10}$/,
      message: 'Please enter a 10-digit mobile number.',
    },
  ],
  password: [
    {
      required: true,
      message: 'Please input your password',
    },
  ],
  companyName: [
    {
      required: true,
      message: 'Please input your name',
    },
  ],
  confirm: [
    {
      required: true,
      message: 'Please confirm your password!',
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (value && getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject('Password do not match!');
      },
    }),
  ],
  CompanyList: [
    {
      required: true,
      message: 'please select the company list',
    },
  ],
};

export const SignUpForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [isOtpVisible, setIsOtpVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');

  const { t } = useTranslation();

  const handleSubmit = (values: SignUpFormData) => {
    setLoading(true);
    dispatch(doSignUp(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: t('auth.signUpSuccessMessage'),
          description: t('auth.signUpSuccessDescription'),
        });
        navigate('/auth/login');
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const onFinish = async () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        dispatch(addCompany(values))
          .then((res) => {
            setLoading(false);
            setIsOtpVisible(true);
            if (res?.payload.status == 200) {
              setEmail(values?.email);
              setTimeout(() => notificationController.success({ message: 'Otp sent successfully.' }), 1000);
            } else {
              setLoading(false);
              notificationController.error({ message: res?.payload?.message || 'Something went wrong' });
            }
          })
          .catch((error) => {
            notificationController.error({ message: error || 'Something went wrong' });
            setLoading(false);
          });
      })
      .catch((info) => {
        setLoading(false);
        notificationController.error({ message: 'Please enter all required field' });
      });
  };

  const VerifyOtp = async () => {
    setIsVerifyLoading(true);
    dispatch(companyVerifyOtp({ email: email, otp: otp }))
      .then((res) => {
        setIsVerifyLoading(false);
        if (res?.payload.status == 200) {
          navigate('/auth/login');
          setTimeout(
            () => notificationController.success({ message: 'Congratulation your account!, Registered successfully.' }),
            1000,
          );
        } else {
          setIsVerifyLoading(false);
          notificationController.error({ message: res?.payload?.message || 'Something went wrong' });
        }
      })
      .catch((error) => {
        notificationController.error({ message: error || 'Something went wrong' });
        setIsVerifyLoading(false);
      });
  };

  return (
    // <Auth.FormWrapper>
    //   <BaseForm onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
    //     <S.Title>{t('common.signUp')}</S.Title>
    //     <Auth.FormItem
    //       name="firstName"
    //       label={t('common.firstName')}
    //       rules={[{ required: true, message: t('common.requiredField') }]}
    //     >
    //       <Auth.FormInput placeholder={t('common.firstName')} />
    //     </Auth.FormItem>
    //     <Auth.FormItem
    //       name="lastName"
    //       label={t('common.lastName')}
    //       rules={[{ required: true, message: t('common.requiredField') }]}
    //     >
    //       <Auth.FormInput placeholder={t('common.lastName')} />
    //     </Auth.FormItem>
    //     <Auth.FormItem
    //       name="email"
    //       label={t('common.email')}
    //       rules={[
    //         { required: true, message: t('common.requiredField') },
    //         {
    //           type: 'email',
    //           message: t('common.notValidEmail'),
    //         },
    //       ]}
    //     >
    //       <Auth.FormInput placeholder={t('common.email')} />
    //     </Auth.FormItem>
    //     <Auth.FormItem
    //       label={t('common.password')}
    //       name="password"
    //       rules={[{ required: true, message: t('common.requiredField') }]}
    //     >
    //       <Auth.FormInputPassword placeholder={t('common.password')} />
    //     </Auth.FormItem>
    //     <Auth.FormItem
    //       label={t('common.confirmPassword')}
    //       name="confirmPassword"
    //       dependencies={['password']}
    //       rules={[
    //         { required: true, message: t('common.requiredField') },
    //         ({ getFieldValue }) => ({
    //           validator(_, value) {
    //             if (!value || getFieldValue('password') === value) {
    //               return Promise.resolve();
    //             }
    //             return Promise.reject(new Error(t('common.confirmPasswordError')));
    //           },
    //         }),
    //       ]}
    //     >
    //       <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
    //     </Auth.FormItem>
    //     <Auth.ActionsWrapper>
    //       <BaseForm.Item name="termOfUse" valuePropName="checked" noStyle>
    //         <Auth.FormCheckbox>
    //           <Auth.Text>
    //             {t('signup.agree')}{' '}
    //             <Link to="/" target={'_blank'}>
    //               <Auth.LinkText>{t('signup.termOfUse')}</Auth.LinkText>
    //             </Link>{' '}
    //             and{' '}
    //             <Link to="/" target={'_blank'}>
    //               <Auth.LinkText>{t('signup.privacyOPolicy')}</Auth.LinkText>
    //             </Link>
    //           </Auth.Text>
    //         </Auth.FormCheckbox>
    //       </BaseForm.Item>
    //     </Auth.ActionsWrapper>
    //     <BaseForm.Item noStyle>
    //       <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
    //         {t('common.signUp')}
    //       </Auth.SubmitButton>
    //     </BaseForm.Item>
    //     <BaseForm.Item noStyle>
    //       <Auth.SocialButton type="default" htmlType="submit">
    //         <Auth.SocialIconWrapper>
    //           <GoogleIcon />
    //         </Auth.SocialIconWrapper>
    //         {t('signup.googleLink')}
    //       </Auth.SocialButton>
    //     </BaseForm.Item>
    //     <BaseForm.Item noStyle>
    //       <Auth.SocialButton type="default" htmlType="submit">
    //         <Auth.SocialIconWrapper>
    //           <FacebookIcon />
    //         </Auth.SocialIconWrapper>
    //         {t('signup.facebookLink')}
    //       </Auth.SocialButton>
    //     </BaseForm.Item>
    //     <Auth.FooterWrapper>
    //       <Auth.Text>
    //         {t('signup.alreadyHaveAccount')}{' '}
    //         <Link to="/auth/login">
    //           <Auth.LinkText>{t('common.here')}</Auth.LinkText>
    //         </Link>
    //       </Auth.Text>
    //     </Auth.FooterWrapper>
    //   </BaseForm>
    // </Auth.FormWrapper>

    <>
      {!isOtpVisible ? (
        <div style={{ padding: '40px' }}>
          <div className="container login-auto-signup">
            <div className="form-signup">
              <Row justify="center">
                <Col xs={24} sm={24} md={20} lg={20}>
                  <Form form={form} layout="vertical" name="register-form">
                    <h1>Create a new account:</h1>
                    {/* onFinish={onSignUp} */}
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item name="companyName" label="Comapany Name" rules={rules.companyName} hasFeedback>
                          <Input prefix={<MailOutlined className="text-primary" />} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item name="email" label="Email" rules={rules.email} hasFeedback>
                          <Input prefix={<MailOutlined className="text-primary" />} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item name="mobileNo" label="Mobile No." rules={rules.mobile} hasFeedback>
                          <Input prefix={<MailOutlined className="text-primary" />} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item name="password" label="Password" rules={rules.password} hasFeedback>
                          <Input.Password prefix={<LockOutlined className="text-primary" />} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item name="confirm" label="Confirm Password" rules={rules.confirm} hasFeedback>
                          <Input.Password prefix={<LockOutlined className="text-primary" />} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div style={{ paddingTop: '70px' }}>
                      <Row gutter={16}>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item>
                            <Button type="reset" htmlType="submit" block onClick={() => navigate(-1)}>
                              Back
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item>
                            <Button type="primary" htmlType="submit" block loading={isLoading} onClick={onFinish}>
                              Sign Up
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ padding: '40px' }}>
          <div className="container login-auto">
            <div className="form">
              <>
                {/* <div className='arrow-otp'> */}
                <span onClick={() => setIsOtpVisible(false)}>
                  <i className="far fa-arrow-alt-circle-left" style={{ fontSize: '30px' }}></i>
                </span>
                <div className="title-otp">Verification</div>
                <div className="form-grp">
                  <input
                    type="text"
                    className="for-otp"
                    placeholder="ENTER OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>
                <p className="title-otps">
                  We have sent a verification code to the email<br></br>
                  <span className="mail-otps"> {email}</span>
                </p>
                <div style={{ paddingLeft: '200px', paddingTop: '40px' }}>
                  <Button htmlType="submit" type="primary" loading={isVerifyLoading} onClick={VerifyOtp}>
                    Verify
                  </Button>
                </div>
                {/* <p className="forgot">Unable to receive OTP.<a href='#' onClick={() => onLogin('F')}>  Resend Otp?</a></p> */}
              </>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
