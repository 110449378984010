import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getLoggedInCompanyId, readToken } from '@app/services/localStorage.service';
import { EmailConfigurationModel } from '@app/domain/EmailConfigurationModel';

export interface EmailConfigurationState {
  loading: boolean;
  emailConfigurations: EmailConfigurationModel[] | [];
  emailConfiguration: EmailConfigurationModel | null;
}

const initialState: EmailConfigurationState = {
  loading: false,
  emailConfigurations: [],
  emailConfiguration: null,
};

export const addEmailConfiguration = createAsyncThunk(
  '/emailConfiguration/add-emailConfiguration',
  async (values, thunkApi) => {
    try {
      const response = await axios.post<EmailConfigurationModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/EmailConfiguration/Config/Save`,
        { ...values, companyId: getLoggedInCompanyId() },
        {
          headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);

export const EmailConfigurationList = createAsyncThunk(
  '/emailConfiguration/emailConfiguration-list',
  async (_, thunkApi) => {
    try {
      const response = await axios.get<EmailConfigurationModel[]>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/EmailConfiguration/GetAllConfig`,
        {
          params: {
            companyId: getLoggedInCompanyId(),
          },
          headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);

export const UpdateEmailConfiguration = createAsyncThunk(
  'emailConfiguration/update-emailConfiguration',
  async (values, thunkApi) => {
    console.log(values);
    try {
      const response = await axios.put<EmailConfigurationModel>(
        `${process.env.REACT_APP_BASE_URL}/Ecommerce/EmailConfiguration/Config/Update`,
        {...values, companyId: getLoggedInCompanyId()},
        {
          headers: { 'X-Auth-Token': 'Bearer ' + readToken() },
        },
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
    }
  },
);

const emailConfigurationSlice = createSlice({
  name: 'emailConfiguration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(EmailConfigurationList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EmailConfigurationList.fulfilled, (state, action: PayloadAction<EmailConfigurationModel[]>) => {
      state.loading = false;
      state.emailConfigurations = action.payload;
    });
    builder.addCase(EmailConfigurationList.rejected, (state, action) => {
      state.loading = false;
      state.emailConfigurations = [];
    });
  },
});

export const thunks = {
  EmailConfigurationList,
  UpdateEmailConfiguration,
  addEmailConfiguration,
};

export default emailConfigurationSlice.reducer;
